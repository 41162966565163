<template>
  <div>
    <v-form>
      <v-container>
        <p class="mb-2">
          Rank each of the solvents listed below from most polar down to least polar.
        </p>

        <drag-to-rank-input
          v-model="inputs.dragToRankAnswers"
          :items="optionsShuffled"
          class="my-5"
          prepend-text="Most polar"
          append-text="Least polar"
        />
      </v-container>
    </v-form>
  </div>
</template>

<script>
import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default defineComponent({
  name: 'ChemUCI51lbA5_Q6',
  components: {DragToRankInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        dragToRankAnswers: [],
      },
      dragToRankOptions: ['Water', 'Acetonitrile', 'Dioxane', 'Acetone', 'Ethyl acetate', 'Hexane'],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.dragToRankOptions, this.seed);
    },
  },
});
</script>
